import React, { useEffect, useState, useContext, useRef } from 'react'
import { connect } from 'formik'
import { contains } from 'ramda'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Search from '@material-ui/icons/Search'
import MenuItem from '@material-ui/core/MenuItem'
import NavContext from 'components/NavProvider'
import Image from 'components/Image'
import Link from 'components/Link'
import { baseColors } from 'utils/theme'
import { color } from 'utils/style'

const Container = styled.div`
  flex: ${({ flex }) => flex};
  label {
    z-index: 1;
  }
`

const RadioFormControlLabel = styled(FormControlLabel)`
  min-width: calc(50% - 2px);
`

const StyledRadioGroup = styled(RadioGroup)`
  flex-direction: row;
  flex: 1;
`

const StyledRadio = styled(Radio)``

function StoryblokInput(props) {
  const { data, formik, noLabel, ...rest } = props

  const field = {
    ...data.field?.option,
    options: data.field?.option?.data?.options
      ?.map(opt => ({
        ...opt,
        label: data.field.optionLabels[`label_${opt.id}`],
      }))
      .filter(opt => opt.label),
  }

  if (field.type === 'checkbox') {
    return (
      <Container flex={data.flex}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values[field.name]}
              name={field.name}
              id={field.name}
              required={data.required}
            />
          }
          label={data.label}
        />
      </Container>
    )
  }
  if (field.type === 'radio') {
    return (
      <Container flex={field.flex}>
        <StyledRadioGroup
          name={field.name}
          id={field.name}
          onChange={formik.handleChange}
          value={formik.values[field.name]}
          required={data.required}
        >
          {field.options?.map(({ label, name, id }) => (
            <RadioFormControlLabel
              key={id}
              value={name}
              control={<StyledRadio />}
              label={label}
            />
          ))}
        </StyledRadioGroup>
      </Container>
    )
  }
  /* if (data.type === 'select') {
    return (
      <SelectContainer {...props}>
        {!noLabel && (
          <StyledInputLabel htmlFor={data.name}>{data.label}</StyledInputLabel>
        )}
        <StyledSelect
          label={data.label}
          name={data.name}
          input={<StyledInput id={data.name} />}
          displayEmpty
          value={formik.values[data.name]}
          onChange={formik.handleChange}
        >
          {data.selectOptions?.map(({ label, value, id }) => (
            <MenuItem key={id} value={value}>
              {label}
            </MenuItem>
          ))}
        </StyledSelect>
      </SelectContainer>
    )
  } */
  if (field.type === 'number' || field.type === 'email') rest.type = field.type
  return (
    <Container flex={data.flex}>
      <TextField
        InputLabelProps={{ shrink: !!formik.values[field.name] }}
        required={data.required}
        select={field.type === 'select'}
        fullWidth
        multiline={field.type === 'textarea'}
        rows={5}
        id={field.name}
        name={field.name}
        label={!noLabel && data.label}
        placeholder={data.placeholder}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[field.name]}
        InputProps={{
          style: {
            backgroundColor: 'white',
          },
        }}
        {...field.type === 'select' && {
          SelectProps: {
            inputProps: {
              required: !!data.required,
              type: 'text',
              style: {
                backgroundColor: 'transparent',
                color: 'transparent',
                border: 'none',
                position: 'absolute',
                pointerEvents: 'none'
              },
            },
          },
        }}
        {...rest}
      >
        {field.type === 'select' &&
          field.options?.map(({ name, id, label }) => (
            <MenuItem key={id} value={name}>
              {label}
            </MenuItem>
          ))}
      </TextField>
    </Container>
  )
}

export default connect(StoryblokInput)
